<template>
  <div v-if="date" class="text-center">
    <label v-html="label" class="mb-3" style="font-weight: bold;"></label>
    <h3 class="mb-0">{{display}}</h3>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      date: null,
      display: null
    }
  },
  props: {
    label: String,
    source: String
  },
  methods: {
    updateTimer() {
        const future = new Date(this.date);
        const now = new Date();
        const diff = future - now;

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const mins = Math.floor(diff / (1000 * 60));
        const secs = Math.floor(diff / 1000);

        const d = days;
        const h = hours - days * 24;
        const m = mins - hours * 60;
        const s = secs - mins * 60;

        this.display = d + ' days ' + h + ' hours ' + + m + ' minutes ' + s + ' seconds ';

        this.$forceUpdate();
    }
  },
  mounted() {
    if (this.source == 'DigitalQuoteTermRateCalcValidThru') {
      //fetch source of Expiration 
    }

    if (this.date) {
      this.updateTimer();
      setInterval(this.updateTimer, 1000);
    }
    
  }
}
</script>
